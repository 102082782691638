// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-js": () => import("./../../../src/pages/intro.js" /* webpackChunkName: "component---src-pages-intro-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-pro-simulator-js": () => import("./../../../src/pages/pro-simulator.js" /* webpackChunkName: "component---src-pages-pro-simulator-js" */),
  "component---src-pages-router-js": () => import("./../../../src/pages/router.js" /* webpackChunkName: "component---src-pages-router-js" */),
  "component---src-pages-showroom-js": () => import("./../../../src/pages/showroom.js" /* webpackChunkName: "component---src-pages-showroom-js" */),
  "component---src-pages-simulator-js": () => import("./../../../src/pages/simulator.js" /* webpackChunkName: "component---src-pages-simulator-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-race-js": () => import("./../../../src/templates/race.js" /* webpackChunkName: "component---src-templates-race-js" */),
  "component---src-templates-races-js": () => import("./../../../src/templates/races.js" /* webpackChunkName: "component---src-templates-races-js" */)
}

